import React from "react"

// Import Images
import AppStoreIcon from '../images/icons/brands/app-store-icon.svg'
import GooglePlayIcon from '../images/icons/brands/google-play-icon.svg'

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Import Reveal Effects
import Fade from 'react-reveal/Fade';

const Thankyou = () => {
  
  return (
    <Layout>
      <SEO title="Thankyou - Rooam" />
      <div className="section more-top no-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top">Thank you</h1>
          <p className="lead text-light-grey">We are so glad that you had a great experience. Do you mind leaving us a review on the App Store or Google Play Store?</p>
        </div>
      </div>
      <div className="section less-top">
        <div className="container medium columns">
          <div className="row clearfix">
            <Fade bottom distance={'1em'} duration={500}>
              <div className="column break-full">
                <div className="padding">
                  <a href="https://apps.apple.com/us/app/rooam/id1141914731" target="_blank" rel="noopener noreferrer">
                    <div className="box link background-dark-grey no-top-bottom">
                      <div className="box-padding text-center">
                        <div className="link-figure text-center">
                          <img src={AppStoreIcon} alt="Mail Icon" />
                        </div>
                        <p className="font-weight-500 text-white no-top-bottom">App Store</p>
                        <div className="link-arrow text-center no-bottom">
                          <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 14 14"><path d="M3.8,0v1.5h4.9L0,10.2l1.1,1.1l8.7-8.7v4.9h1.5V0H3.8z"/></svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Fade>
            <Fade bottom distance={'1em'} duration={500} delay={200}>
              <div className="column break-full">
                <div className="padding">
                  <a href="https://play.google.com/store/apps/details?id=co.rooam.rooamapp" target="_blank" rel="noopener noreferrer">
                    <div className="box link background-dark-grey no-top-bottom">
                      <div className="box-padding text-center">
                        <div className="link-figure text-center">
                          <img src={GooglePlayIcon} alt="Phone Icon" />
                        </div>
                        <p className="font-weight-500 text-white no-top-bottom">Google Play Store</p>
                        <div className="link-arrow text-center no-bottom">
                          <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 14 14"><path d="M3.8,0v1.5h4.9L0,10.2l1.1,1.1l8.7-8.7v4.9h1.5V0H3.8z"/></svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Thankyou
